import { useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Icon,
  Thumbnail,
  Text,
  Button,
  TextField,
  Select,
  Checkbox,
  InlineStack,
  BlockStack,
  Divider,
} from '@shopify/polaris';
import { DragHandleMinor, CancelSmallMinor } from '@shopify/polaris-icons';
import { VariantPicker, Flex } from '@/components/library';
import styled from 'styled-components';
import { getSizedImageUrl } from '@shopify/theme-images';

const Product = ({
  product,
  variants,
  setVariants,
  variantList,
  index,
  discounts,
  updateDiscount,
  quantity,
  updateQuantity,
  selectionEnabled = false,
  selected,
  updateSelected,
  isDeletable,
  onDelete,
  move,
  draggable,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: product.id,
    disabled: !draggable,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { id } = product;
  const { title, image } = product.attributes;

  const discount = discounts && discounts.find((d) => d.id === id);
  const quantityItem = quantity && quantity.find((d) => d.id === id);

  const handleDiscountValueChange = useCallback(
    (value) =>
      updateDiscount({
        id,
        value: parseFloat(value),
      }),
    [updateDiscount]
  );

  const handleDiscountTypeChange = useCallback(
    (value) =>
      updateDiscount({
        id,
        type: value,
      }),
    [updateDiscount]
  );

  const handleQuantityChange = useCallback(
    (value) =>
      updateQuantity({
        id,
        value: parseInt(value, 10),
      }),
    [updateQuantity]
  );

  const handleSelectedChange = useCallback(
    (value) =>
      updateSelected({
        id,
        value,
      }),
    [id, updateSelected]
  );

  const handleDelete = useCallback(() => onDelete(id), [onDelete]);
  const handleVariantChange = useCallback(
    (value) => {
      const newValue = { ...variantList, [product.id]: value };
      if (value === 'all') delete newValue[product.id];
      setVariants(newValue);
    },
    [variantList, setVariants]
  );

  return (
    <Row ref={setNodeRef} style={style} isDragging={isDragging}>
      {draggable && (
        <div
          {...attributes}
          {...listeners}
          style={{ position: 'relative', cursor: 'grab' }}
        >
          <Icon source={DragHandleMinor} tone="base" />
        </div>
      )}
      <Flex noshrink>
        <Thumbnail
          source={image ? getSizedImageUrl(image.src, 'thumb') : ''}
          size="small"
          alt={title}
        />
      </Flex>
      <Flex grow>
        <BlockStack gap="100">
          <BlockStack>
            <Text>{title}</Text>
            {/* <Divider /> */}
          </BlockStack>
          <BlockStack>
            <InlineStack gap="200">
              <Text as="p" tone="subdued">
                {variants && variants.length > 0
                  ? `${variants.length} variants`
                  : 'All variants'}
              </Text>
              {setVariants && (
                <VariantPicker
                  product={product}
                  value={variants}
                  setValue={handleVariantChange}
                />
              )}
            </InlineStack>
            {selectionEnabled && (
              <Checkbox
                label="Preselected"
                checked={selected.find((s) => s.id === id)?.value}
                onChange={handleSelectedChange}
              />
            )}
          </BlockStack>
        </BlockStack>
      </Flex>
      {isDeletable && (
        <Button
          icon={CancelSmallMinor}
          onClick={handleDelete}
          variant="plain"
        />
      )}
      {discount && (
        <div style={{ minWidth: '140px', maxWidth: '140px' }}>
          <TextField
            type="number"
            value={discount.value}
            onChange={handleDiscountValueChange}
            // label="Discount value"
            connectedRight={
              <Select
                value={discount.type}
                onChange={handleDiscountTypeChange}
                options={[
                  { label: '%', value: 'percentage' },
                  { label: '$', value: 'fixed_amount' },
                ]}
              />
            }
          />
        </div>
      )}
      {quantityItem && (
        <TextField
          type="number"
          value={quantityItem.value}
          onChange={handleQuantityChange}
          min={1}
        />
      )}
    </Row>
  );
};

const Row = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  gap: 24px;

  &:not(:last-child) {
    border-bottom: ${(props) =>
      props.isDragging ? 'none' : `1px solid rgb(215, 219, 223)`};
  }
`;

export default Product;
