import { useStore } from '@/hooks';
import { InlineStack, Text, Select } from '@shopify/polaris';
import { useLanguage } from '@/hooks';

export const MarketSelector = ({ name, label, ...props }) => {
  const { store } = useStore();
  const { markets } = store || {};
  const { market, changeMarket } = useLanguage();

  if (!markets || markets.length < 2) return null;

  const options = markets
    .filter((e) => e.enabled)
    .sort((a, b) => (b.primary ? 1 : 0) - (a.primary ? 1 : 0))
    .map((item) => ({
      label: item.name,
      value: item.primary ? 'default' : item.id,
    }));

  return (
    <Select
      label="Market: "
      labelInline
      options={options}
      value={market}
      onChange={(value) => changeMarket(value)}
      {...props}
    />
  );
};
