import { useStore } from '@/hooks';
import { InlineStack, Text, Select } from '@shopify/polaris';
import { useLanguage } from '@/hooks';

export const LanguageSelector = ({ name, label, ...props }) => {
  const { store } = useStore();
  const { locales } = store || {};
  const { language, changeLanguage } = useLanguage();

  const options = locales.map((locale) => ({
    label: locale.primary ? 'Default' : locale.name,
    value: locale.primary ? 'default' : locale.locale,
  }));

  if (!locales || locales.length < 2) return null;

  return (
    <Select
      label="Language: "
      labelInline
      options={options}
      value={language}
      onChange={(value) => changeLanguage(value)}
      {...props}
    />
  );
};
