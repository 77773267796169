import { useField } from 'formik';
import { Checkbox } from '@shopify/polaris';

const CheckboxField = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const { value } = field;
  const { setValue } = helpers;

  return (
    <Checkbox
      id={name}
      name={name}
      checked={value}
      onChange={(val) => setValue(val)}
      {...props}
    />
  );
};

export default CheckboxField;
