import { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { BlockStack } from '@shopify/polaris';
import Product from './product';

const ProductList = ({
  products = [],
  setProducts,
  variants,
  setVariants,
  selectionEnabled = false,
  selected = [],
  setSelected,
  discounts,
  setDiscounts,
  quantity,
  setQuantity,
  isDeletable = true,
  isOrderable = false,
}) => {
  useEffect(() => {
    if (discounts) {
      const missing = products
        .filter((p) => !discounts.some((d) => d.id === p.id))
        .map((p) => ({
          id: p.id,
          value: '',
          type: 'percentage',
        }));

      if (missing.length > 0) {
        setDiscounts([...discounts, ...missing]);
      }

      const removed = discounts.filter(
        (d) => !products.some((p) => p.id === d.id)
      );

      if (removed.length > 0) {
        setDiscounts(
          discounts.filter((d) => !removed.some((r) => r.id === d.id))
        );
      }
    }

    if (quantity) {
      const missing = products
        .filter((p) => !quantity.some((d) => d.id === p.id))
        .map((p) => ({
          id: p.id,
          value: 1,
        }));

      if (missing.length > 0) {
        setQuantity([...quantity, ...missing]);
      }

      const removed = quantity.filter(
        (d) => !products.some((p) => p.id === d.id)
      );

      if (removed.length > 0) {
        setQuantity(
          quantity.filter((d) => !removed.some((r) => r.id === d.id))
        );
      }
    }
  }, [products]);

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = products.findIndex((p) => p.id === active.id);
        const newIndex = products.findIndex((p) => p.id === over.id);

        const newArray = Array.from(products);
        const [removed] = newArray.splice(oldIndex, 1);
        newArray.splice(newIndex, 0, removed);

        setProducts(newArray);
      }
    },
    [products, setProducts]
  );

  const handleDelete = useCallback(
    (id) => {
      setProducts(products.filter((p) => p.id !== id));
    },
    [products]
  );

  const updateDiscount = useCallback(
    (data) => {
      let discount = discounts.find((d) => d.id === data.id) || {};
      const updated = { ...discount, ...data };
      setDiscounts([...discounts.filter((d) => d.id !== data.id), updated]);
    },
    [discounts, setDiscounts]
  );

  const updateQuantity = useCallback(
    (data) => {
      let q = quantity.find((d) => d.id === data.id) || {};
      const updated = { ...q, ...data };
      setQuantity([...quantity.filter((d) => d.id !== data.id), updated]);
    },
    [quantity, setQuantity]
  );

  const updateSelected = useCallback(
    (data) => {
      const q = selected.find((d) => d.id === data.id) || {};
      const updated = { ...q, ...data };

      setSelected([...selected.filter((d) => d.id !== data.id), updated]);
    },
    [selected, setSelected]
  );

  if (products.length === 0) return null;

  const draggable = isOrderable && products.length > 1;

  return (
    <Wrapper border="muted">
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={products.map((p) => p.id)}
          strategy={verticalListSortingStrategy}
        >
          <BlockStack>
            {products.map((product, index) => (
              <Product
                key={product.id}
                product={product}
                variants={variants && variants[product.id]}
                setVariants={setVariants}
                variantList={variants}
                index={index}
                discounts={discounts}
                updateDiscount={updateDiscount}
                quantity={quantity}
                updateQuantity={updateQuantity}
                selectionEnabled={selectionEnabled}
                selected={selected}
                updateSelected={updateSelected}
                isDeletable={isDeletable}
                onDelete={handleDelete}
                draggable={draggable}
              />
            ))}
          </BlockStack>
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid rgb(215, 219, 223);
`;

export default ProductList;
